<template>
  <div class="evaluation">
    <!-- 心理测评 -->
    <div class="introduce">
      <div class="WebMain">
        <p class="DivTitle">Fast, Accurate, Authoritative Evaluation System</p>
        <!-- <p class="p17 text">
          心理测评系统是依据专业的心理学理论，使用高度智能化的操作程序，给人的能力、人格及心理健康等心理特性和行为确定出一种数量化的价值。将人的某些心理特征数量化，来衡量个体心理因素水平和个体心理差异的一种科学测量方法。智涵易兴提供心理测评系统定制开发，所有功能可控可改。
        </p> -->
        <div class="content">
          <div class="left">
            <img src="../../assets/img/evaluation/en编组 12备份 4@2x.png" alt="">
            <p class="p17">
              Psychological Evaluation System utilizes advanced artificial intelligence technology, natural language processing technology, and neural network deep learning technology, combined with cutting-edge theories of neuroscience, to optimize the process of data analysis and management, enhancing the understanding and assessment of the psychological state of the assessed individual. Our professional medical team and deep learning algorithm technology personnel provide continuous optimization and upgrading of the intelligent system, providing users with accurate, scientific, and authoritative psychological evaluation and analysis of mental health trends.

            </p>
            <!-- <p class="p17">
              Multi-platform compatibility: Supports PC, tablet, mobile phone, WeChat mini-program, and other platforms.
            </p>
            <p class="p17">Multi-dimensional models: Provides multiple evaluation models, including macro, micro,
              physiological, psychological, and other dimensions. </p>
            <p class="p17">
              Accurate data: Uses cutting-edge big data analysis technology to ensure the accuracy and reliability of
              assessment data.
              Personalized analysis: Provides personalized analysis and recommendations to achieve precise management of
              each user.
            </p> -->
          </div>
          <div class="right">
            <img src="../../assets/img/evaluation/三端@2x.png" alt="">
          </div>
        </div>
        <div class="testBtn">
          <a href="https://ig.zhihanyixing.com" target="blank">
            <span> Try now</span></a>
        </div>
      </div>
    </div>
    <div class="function">
      <div class="WebMain">
        <p class="DivTitle">Technical highlights and functional advantages</p>
        <div class="DivContent">
          <div class="item" v-for="item in functionList" :key="item.id">
            <p class="num p50">0{{ item.id }}</p>
            <p class="title p25">{{ item.title }}</p>
            <p class="content p17">{{ item.content }}</p>
            <div class="bottomStyle"></div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$store.state.isPhone ? 'phone development' : 'development'">
      <div class="WebMain">
        <p class="DivTitle">customized development of special functions and scales</p>
        <div class="DivContent">
          <div class="item " v-for="item in developmentList" :key="item.id">
            <img class="icon" :src="require(`../../assets/img/evaluation/${item.icon}`)" alt="">
            <img class="hoverIcon" :src="require(`../../assets/img/evaluation/${item.hoverIcon}`)" alt="">
            <div class="content">

              <p class="top p24">
                {{ item.title }}
              </p>
              <p class="text">
                {{ item.content }}
              </p>
            </div>
            <div class="leftStyle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      functionList: [
        { id: 1, title: 'Evaluation tool library', content: 'The system includes more than 40 commonly used scales, supports custom scales and reports, and allows editing and revision of norms, result interpretations, counseling advice, reference values, etc.' },
        { id: 2, title: 'Automatic evaluation report', content: "Generates professional and detailed evaluation reports that analyze and explain the user’s psychological state and provide corresponding advice and action plans. Supports report grading, permission management, and exporting to PDF, Word, and image formats, and allows unlimited printing. " },
        { id: 3, title: 'Hierarchical management', content: 'The system adopts advanced system permissions in combination with organizational structure management concepts. Through multiple roles such as administrators, operators, consultants, and users, and combined with a tree-like organizational structure, it can adapt to the actual situations of different organizations and enterprises.' },
        { id: 4, title: 'Safe and reliable', content: 'The system uses advanced asymmetric data encryption technology and multiple security and confidentiality measures to ensure the security and confidentiality of user information and test results. The system ensures that evaluative results can only be viewed and used by authorized users, guaranteeing data confidentiality and integrity.' },
        { id: 5, title: 'Scientific and standardized', content: 'The scales and reports are designed by a team of professional physicians and psychological consultants based on scientific theory and research, using standardized measurement tools to ensure the reliability and accuracy of assessment results.' },
        { id: 6, title: 'Data analysis and visualization', content: 'Helps users better understand and interpret test results through data analysis and visualization functions. Supports multi-level data statistics and analysis, and group reports with graphical and written content.' },
      ],
      developmentList: [
        { id: 1, title: 'Customization', content: 'Customize exclusive features and scales to meet your development needs to the maximum extent. ', icon: 'icon (1).png', hoverIcon: 'hover (1).png' },
        { id: 2, title: ' Source code delivery', content: ' After development is completed, the complete source code will be delivered.', icon: 'icon (2).png', hoverIcon: 'hover (2).png' },
        { id: 3, title: 'Lifetime usage', content: 'One-time purchase for lifetime usage, with no hidden charges.', icon: 'icon (3).png', hoverIcon: 'hover (3).png' },
        { id: 4, title: 'Update iteration', content: 'Continuous optimization, update iteration, and gradual improvement as per your needs.', icon: 'icon (4).png', hoverIcon: 'hover (4).png' },
        { id: 5, title: 'Contract guarantee', content: 'Contract software customization service for quality and guaranteed after-sales.', icon: 'icon (5).png', hoverIcon: 'hover (5).png' },
        { id: 6, title: 'Free color matching', content: 'Provide rich theme colors and interface customization services.', icon: 'icon (6).png', hoverIcon: 'hover (6).png' },
      ]
    }
  },
  methods: {
    // // 跳转到心理测评
    // toTest() {

    // }
  }
}
</script>

<style lang="scss" scoped>
.evaluation {

  .introduce {
    padding-bottom: 60px;
    background: url('../../assets/img/evaluation/bg.png');
    background-size: cover;

    .content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 20px;

      .left {
        width: 695px;

        img {
          height: 65px;
        }

        p {
          color: #666666;
          line-height: 27px;
          margin-top: 20px;
        }
      }

      .right {
        padding-top: 20px;

        img {
          width: 410px;
        }


      }

    }

    .testBtn {
      margin-top: 30px;

      a {
        text-decoration: none;
      }

      span {
        cursor: pointer;
        margin: auto;
        text-align: center;
        display: block;
        width: 148px;
        height: 42px;
        border-radius: 31px;
        border: 1px solid #93D0CF;
        font-size: 16px;
        font-weight: 600;
        color: #93D0CF;
        line-height: 42px;
      }

      &:hover {
        span {
          transition: all .5s;
          background: #93D0CF;
          color: white;
        }
      }
    }
  }

  .function {
    background: url('../../assets/img/evaluation/bg2.png');
    background-size: cover;

    .DivContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        width: 560px;
        box-sizing: border-box;
        padding: 20px 0;
        position: relative;
        margin-bottom: 40px;

        &:hover {

          .bottomStyle {
            transition: all .5s;
            width: 100%;
          }

          .title {
            transition: all .5s;
            color: var(--custom-color);
          }
        }

        .bottomStyle {
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 0;
          height: 2px;
          background: var(--custom-color);
        }

        .num {
          position: absolute;
          top: -10px;
          font-weight: 600;
          color: var(--custom-color);
          opacity: 0.3;

        }

        .title {
          font-weight: 600;
          color: #222222;
          line-height: 36px;
          margin-bottom: 20px;
        }

        .content {
          color: #666666;
          line-height: 27px;
        }
      }
    }
  }

  .development {
    // background: #F5F5F6;
    background: linear-gradient(180deg, #F8FDFF 0%, #D5EDF3 100%);
    padding-bottom: 60px;

    .DivContent {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .item {
        box-sizing: border-box;
        width: 390px;
        height: 160px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        position: relative;

        &:hover {
          .hoverIcon {
            display: block;
          }

          .icon {
            display: none;
          }

          .leftStyle {
            height: 100%;
            transition: all .5s;
          }
        }

        img {
          width: 92px;
          position: absolute;
          top: 0;
          right: 0;

        }

        .hoverIcon {
          display: none;
        }

        .content {
          padding: 24px 35px;

          .top {
            margin-bottom: 16px;

          }



          .text {
            color: #666666;
            line-height: 27px;
          }
        }

        .leftStyle {
          position: absolute;
          left: 0;
          top: 0;
          width: 7px;
          height: 0;
          background: var(--custom-color);
        }
      }
    }
  }
}

.evaluation .development.phone .DivContent .item .hoverIcon {
  display: block;
}

.evaluation .development.phone .DivContent .item .icon {
  display: none;
}

@media screen and (min-width:1180px) {
  .evaluation .introduce .content .left {
    margin-right: 45px;
  }

  .evaluation .function .DivContent .item {

    &:first-child,
    &:nth-child(3),
    &:nth-child(5) {
      margin-right: 60px;
    }
  }
}

@media screen and (max-width:500px) {
  .evaluation .introduce .content .right img {
    width: 100%;
  }
}</style>